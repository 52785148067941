import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { PageType } from "./about"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wave, { WaveType } from "../components/ui/wave"

const TermsOfUse = () => {
  const { graphCmsPage } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckwlr32c8y3nt0a42ctku8uy5:en:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }
    }
  `)

  const data: PageType = graphCmsPage

  return (
    <Layout>
      <SEO title="privacy policy" meta={data.seo} />

      <div
        className="container pt-5"
        style={{
          overflowWrap: "break-word",
        }}
      >
        <section className="pt-5">
          <h2 className="pb-3">{data.heading}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.body.html }} />
        </section>
      </div>

      <Wave pathClassName={"u-c-orange"} type={WaveType.v6} />
    </Layout>
  )
}

export default TermsOfUse
